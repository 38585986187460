<template>
    <div class="flex justify-center mt-2 mb-4">
        <el-input
            ref="codeInput0"
            v-model="confirmationCodeUser[0]"
            class="code-input w-auto mx-1"
            maxlength="1"
            @focus="clear(0)"
            @input="validateNumber(confirmationCodeUser[0], 0)"
        />
        <el-input
            ref="codeInput1"
            v-model="confirmationCodeUser[1]"
            class="code-input w-auto mx-1"
            maxlength="1"
            @focus="clear(1)"
            @input="validateNumber(confirmationCodeUser[1], 1)"
        />
        <el-input
            ref="codeInput2"
            v-model="confirmationCodeUser[2]"
            class="code-input w-auto mx-1"
            maxlength="1"
            @focus="clear(2)"
            @input="validateNumber(confirmationCodeUser[2], 2)"
        />
        <el-input
            ref="codeInput3"
            v-model="confirmationCodeUser[3]"
            class="code-input w-auto mx-1"
            maxlength="1"
            @focus="clear(3)"
            @input="validateNumber(confirmationCodeUser[3], 3)"
            @blur="checkCode"
        />
    </div>
</template>

<script>
export default {
    props: {
        modalVisible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            confirmationCodeUser: [],
            confirmationCode:     null,
        };
    },

    watch: {
        modalVisible(value) {
            this.confirmationCodeUser = [];
            if (!value) return;

            this.$nextTick(() => {
                this.$refs.codeInput0.focus();
            });

            this.confirmationCode = String(parseInt(Math.random() * 9000 + 1000));
            this.$emit('codeChange', this.confirmationCode);
        },
    },

    methods: {
        checkCode() {
            if (this.confirmationCodeUser.join('') === this.confirmationCode) {
                this.$emit('checkCode', true);
                return;
            }
            this.$emit('checkCode', false);
        },

        next(value) {
            this.$refs[`codeInput${value}`].focus();
        },

        clear(value) {
            this.$set(this.confirmationCodeUser, value, null);
            this.checkCode();
        },

        validateNumber(value, index) {
            if (isNaN(parseInt(value)) || value === '') {
                this.$set(this.confirmationCodeUser, index, null);
                this.checkCode();
                return;
            }

            if (index < 3) {
                this.next(index + 1);
            }

            this.checkCode();
        },
    },
};
</script>
